<template>
  <div class="wrapper">
       <div class="sign-flag" :class="{ok: signList.length>20,no:signList.length<21}">
         <div class="sign-flag-info">您共计打卡{{ signList.length }}天，超越了{{ proportion }}%的读者。<span
             v-if="signList.length>20"><br>抽奖开始时间：2021年12月6日</span></div>
       </div>
       <div class="sign-flag ok" v-if="signList.length>=21">
         <router-link tag="div" to="/personal/sign-in/lottery" class="go-lottery" v-if="lottery === ''">
           去抽奖
         </router-link>
         <div tag="div" class="lottery_remark" v-if="lottery === ''">
           抽奖已截止
         </div>
         <div class="lottery_remark" v-else>{{ lottery }}</div>
         <div class="lottery_toast" v-if="lottery !== ''">【领取方式,请等待系统下一步通知】</div>
       </div>
       <!-- <div class="sign-flag no" v-if="signList.length<21">
         <div class="sign-flag-info">您共计打卡{{ signList.length }}天，超越了{{ proportion }}%的读者。</div>
       </div> -->
    <img class="sign-adv"
         src="https://msyd-1304515158.cos.ap-beijing.myqcloud.com/uploads/220518/881fd1c0-1b6b-23d5-dc1a-ef858458ef0d.png"
         alt=""/>
    <div class="sign-body">
      <div class="sign-block">
        <div class="title">2022年05月</div>
        <div class="sign">
          <div class="item week">周一</div>
          <div class="item week">周二</div>
          <div class="item week">周三</div>
          <div class="item week">周四</div>
          <div class="item week">周五</div>
          <div class="item week">周六</div>
          <div class="item week">周日</div>
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
          <div
              class="item date"
              v-for="(item,index) in dateList"
              :key="index"
              @click="sign(item)"
          >
            <div
                class="round"
                :class="{reissue:item.is_before && !item.is_sign,'sing-in':item.is_sign}"
            ></div>
            <div class="text">{{ item.date }}</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce">
      <div class="introduce-block">
        <div class="title-pic"></div>
        <div>1、活动期间，读者在码上悦读平台阅读3页以上或浏览音/视频1分钟以上，即可完成当日打卡；</div>
        <div>2、成功邀请5人注册，获得一次补卡机会；</div>
        <div>3、活动结束后，系统在连续打卡5天的读者中随机抽取100位幸运读者，发放精美奖品一份；</div>
        <div>4、在多个图书馆重复参与活动不增加抽奖机会。</div>
      </div>
    </div>
    <div class="introduce">
      <div class="introduce-block">
        <div class="log-title"></div>
        <div
            class="log-item"
            v-for="(item,index) in signList"
            :key="index"
        >
          <div class="content">{{ item.sign_in_remark }}</div>
          <div class="time">{{ item.create_time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sign',
  props: {
    dateList: {
      type: Array,
      default () {
        return []
      }
    },
    signList: {
      type: Array,
      default () {
        return []
      }
    },
    spread: {
      type: Number,
      default: 0
    },
    proportion: {
      type: String,
      default: '0'
    },
    lottery: {
      type: String,
      default: ''
    }
  },
  methods: {
    sign (item) {
      this.$emit('sign', item)
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  width 100%

  .sign-adv
    position relative
    z-index -1
    display flex
    width 100%
    height 532px
    pointer-events none

  .sign-flag
    display flex
    justify-content center
    flex-wrap wrap
    width 100%
    height 400px
    background-size 100% 100%
    background-repeat no-repeat

    &.ok
      bg-image2('assets/lottery/lottery-bg')

      .sign-flag-info
        text-align center
        margin-top 210px
        color rgba(255, 255, 255, 1)

      .go-lottery
        display flex
        align-items center
        justify-content center
        width 140px
        height 40px
        line-height normal
        margin-top 214px
        font-size 20px
        color rgba(253, 206, 150, 1)
        bg-image2('assets/lottery/go-to-lottery')
        background-size 100% 100%
        background-repeat no-repeat

      .lottery_remark
        display flex
        align-items center
        justify-content center
        width 380px
        height 40px
        line-height normal
        margin-top 214px
        font-size 20px
        color rgba(253, 206, 150, 1)
        bg-image2('assets/lottery/go-to-lottery')
        background-size 100% 100%
        background-repeat no-repeat

      .lottery_toast
        display flex
        justify-content center
        align-items center
        width 100%
        font-size 18px
        color rgba(157, 26, 5, 1)
        margin-top -180px

    &.no
      background-image: url("https://msyd-1304515158.cos.ap-beijing.myqcloud.com/article/images/eadd4fc3-d62f-119a-21c5-b11727807bf7.png")

      .sign-flag-info
        margin-top 182px
        color rgba(116, 107, 81, 1)

    .sign-flag-info
      display flex
      align-items center
      height 50px
      line-height normal
      font-size 24px

  .sign-body
    position relative
    width 750px
    margin-top -20px
    padding 20px 10px
    box-sizing border-box
    border-radius 28px
    background rgba(199, 2, 4, 1)

    &::after
      content ''
      position absolute
      left 0
      top -34px
      width 230px
      height 104px
      bg-image2('uploads/sgin-in/label')
      background-size 100% 100%
      background-repeat no-repeat

    .sign-block
      display flex
      flex-wrap wrap
      justify-content center
      align-items center
      width 100%
      padding-bottom 20px
      box-sizing border-box
      border-radius 28px
      background rgba(173, 3, 5, 1)
      border 1px solid rgba(247, 195, 171, 1)

      .title
        display flex
        align-items center
        justify-content center
        width 750px
        height 80px
        line-height normal
        text-align center
        font-size 26px
        color rgba(255, 255, 255, 1)
        font-weight bold

      .sign
        display flex
        flex-wrap wrap
        width 690px

        .item
          position relative
          display flex
          justify-content center
          flex-wrap wrap
          width 98px

          &.week
            height 30px
            margin-bottom 20px
            line-height normal
            font-size 22px
            color rgba(255, 255, 255, .5)

          &.date
            height 90px

          .round
            position absolute
            z-index 1
            width 46px
            height 46px
            border-radius 23px
            background rgba(255, 255, 255, 1)

            &.reissue
              bg-image2('uploads/sgin-in/reissue')
              background-size 100% 100%
              background-repeat no-repeat

            &.sing-in
              bg-image2('uploads/sgin-in/signed')
              background-size 100% 100%
              background-repeat no-repeat

          .text
            position absolute
            bottom 10px
            display flex
            justify-content center
            align-items center
            width 100%
            line-height normal
            font-size 18px
            color rgba(255, 255, 255, .5)

          .line
            position absolute
            z-index 0
            top 22px
            width 100%
            height 2px
            background rgba(255, 218, 170, 1)

  .introduce
    width 100%
    margin-top 38px
    padding 20px 10px
    box-sizing border-box
    background rgba(199, 2, 4, 1)
    border-radius 28px

    .introduce-block
      width 100%
      padding 20px
      border-radius 28px
      border 1px solid rgba(247, 195, 171, 1)
      box-sizing border-box
      background rgba(173, 3, 5, 1)
      font-size 26px
      color rgba(255, 255, 255, 1)
      line-height 50px

      .title-pic
        width 460px
        height 100px
        margin 0 auto
        bg-image2('uploads/sgin-in/title-pic')
        background-size 100% 100%
        background-repeat no-repeat

      .log-title
        width 472px
        height 100px
        margin 0 auto
        bg-image2('uploads/sgin-in/log-title')
        background-size 100% 100%
        background-repeat no-repeat

      .log-item
        display table
        width 690px
        height 108px
        padding-left 20px
        box-sizing border-box
        margin-top 30px
        bg-image2('uploads/sgin-in/log-bg')
        background-size 100% 100%
        background-repeat no-repeat

        .content
          width 100%
          height 26px
          line-height 30px
          margin-top 20px
          font-size 26px
          color rgba(255, 255, 255, 1)

        .time
          width 100%
          height 22px
          line-height 26px
          margin-top 18px
          font-size 22px
          color rgba(255, 255, 255, 1)
</style>
