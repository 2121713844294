<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="container">
      <scroll
        ref="scroll"
        class="scroll"
        :data="dateList"
        :listen-scroll="true"
        @scroll="scroll"
        :pull-down-refresh="{threshold: 30, stop: 0}"
        @pullingDown="pullDownRefresh"
      >
        <div>
          <div class="pull-down-wrapper">
            <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
            <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
          </div>
          <back back-url="/sign-in" class="back"></back>
          <sign
            :date-list="dateList"
            :sign-list="signList"
            :spread="spread"
            :proportion="proportion"
            :lottery="lottery"
            @sign="sign"
          />
        </div>
      </scroll>
      <router-view></router-view>
    </div>
  </transition>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import Back from '@/components/back/Back'
import Sign from './components/Sign'
import { Toast, Dialog } from 'vant'
import { mapGetters } from 'vuex'
import { getSignIn, signIn } from '@/api/SignIn'

export default {
  name: 'SignIn',
  components: {
    Scroll,
    Sign,
    Back
  },
  data () {
    return {
      isPullingDown: false,
      dateList: [],
      signList: [],
      spread: 0,
      proportion: '',
      lottery: '' // 中奖结果
    }
  },
  computed: {
    ...mapGetters([
      'userGuid',
      'agencyGuid'
    ])
  },
  mounted () {
    this._getSignIn()
  },
  methods: {
    _getSignIn () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getSignIn({
        sign_in_user: this.userGuid,
        sign_in_agency: this.agencyGuid
      }).then(res => {
        const {
          code,
          msg,
          data
        } = res
        if (code === 200) {
          const {
            date,
            response,
            spread,
            proportion,
            lottery
          } = data
          console.log('发啊牛', data)
          this.dateList = date
          this.signList = response
          this.spread = spread
          this.proportion = proportion.toString()
          this.lottery = lottery
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    },
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {
      this._getSignIn()
    },
    sign (item) {
      if (item.is_sign) {
        return Toast.fail('请勿重复签到')
      }
      if (!item.is_before) {
        return Dialog.alert({
          message: '当前页面只允许补签，如您想要签到，请通过阅读书籍、听书、观看视频的方式自动签到。'
        })
      }
      signIn({
        sign_in_user: this.userGuid,
        sign_in_agency: this.agencyGuid,
        sign_in_time: item.base_date,
        sign_in_from: 3
      }).then(res => {
        const {
          code,
          msg
        } = res
        if (code === 200) {
          Toast.success('补签成功')
          this._getSignIn()
        } else {
          Toast.fail(msg)
        }
      })
    }
  },
  watch: {
    $route (to, from) {
      if (this.$route.name === 'SignIn') {
        this._getSignIn()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)
.back
  position absolute
  top 0
  background none
.container
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(126, 0, 1, 1)

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden

    .pull-down-wrapper
      display flex
      justify-content center
      align-items center
      width 750px
      height 70px
      position absolute
      transform translateY(-100%) translateZ(0)

      .pull-down-text
        line-height normal
        font-size 22px
        color rgba(153, 153, 153, 1)
</style>
